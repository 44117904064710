import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import graphic1 from "../../images/hmeGraphic1.svg"
import graphic2 from "../../images/hmeGraphic2.svg"
import graphic3 from "../../images/hmeLogo-w.svg"
import styled from "styled-components"

const Wrapper = styled.section`
  .grid2 {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 576px) {
    .user {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 10px;
    }
    .user p {
      font-size: 12px;
    }
    .users h5 {
      font-size: 0.95rem;
    }
    .persona {
      margin-bottom: 0.5rem;
    }
    .areas {
      grid-template-columns: repeat(1, 1fr);
    }
    .screen {
      margin-bottom: 1rem;
    }
  }
`
const SecondPage = () => (
  <Layout>
    <Seo title="Page two" />
    <Wrapper>
      <div className="project-header">
        <StaticImage
          src="../../images/hme-1/hero.jpg"
          alt="Project"
          placeholder="dominantColor"
          className="hero"
        />
        <div className="project-title">
          <div className="client">
            <img className="client-logo" alt="logo" src={graphic3} />
            <p>/ experience design</p>
          </div>
          <div className="desc">
            <h2>Cross-platform design system</h2>
            <p>
              deploying an all-new global design system and UX toolset across
              corporate and retail platforms
            </p>
          </div>
        </div>
      </div>
      <div className="highlights">
        <h5>Project Scope:</h5>
        <ul className="scope">
          <li>Discovery</li>
          <li>Journeymap</li>
          <li>Work flow</li>
          <li>Wireframe</li>
          <li>Visual Design</li>
          <li>Prototype</li>
          <li>Test</li>
        </ul>
      </div>
      <div className="highlights">
        <h5>Desired Outcomes:</h5>
        <ul>
          <li>
            Define and design product experience across two distinct, but
            connected internal system platforms
          </li>
          <li>
            Develop all-new universal design system and component library for
            application across both experiences
          </li>
        </ul>
      </div>
      <div className="grid grid2">
        <div className="grid-item">
          <img alt="images" src={graphic1} />
          <p>In-store touch screen operations console - “Base Station”</p>
        </div>
        <div className="grid-item">
          <img alt="images" src={graphic2} />
          <p>Cloud Management Dashboard Portal - desktop/mobile</p>
        </div>
      </div>
      <div className="key-areas">
        <h2>PROJECT ENGAGEMENT / 6 months</h2>
        <div className="areas areas2">
          <div className="area">
            <h4>CONSULTING TEAM (project202)</h4>
            <ul>
              <li>Account Exective</li>
              <li>Project Manager</li>
              <li>Experience Design Director (Ned Cannon)</li>
              <li>Product Designer</li>
              <li>Product Designer</li>
              <li>Product Designer</li>
            </ul>
          </div>
          <div className="area">
            <h4>CLIENT STAKEHOLDERS (HME)</h4>
            <ul>
              <li>General Manager, HME Drive-Thru Division</li>
              <li>Program Manager</li>
              <li>Product Manager</li>
              <li>Development Team</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="users">
        <h5>KEY USERS - Base Station</h5>
        <div className="grid user">
          <div className="grid-item">
            <StaticImage
              src="../../images/hme-1/Persona_1.jpg"
              alt="Project"
              placeholder="dominantColor"
              className="persona"
            />
            <p>Crew Members</p>
          </div>
          <div className="grid-item">
            <StaticImage
              src="../../images/hme-1/Persona_2.jpg"
              alt="Project"
              placeholder="dominantColor"
              className="persona"
            />
            <p>Store Manager</p>
          </div>
          <div className="grid-item">
            <StaticImage
              src="../../images/hme-1/Persona_3.jpg"
              alt="Project"
              placeholder="dominantColor"
              className="persona"
            />
            <p>Administrator</p>
          </div>
        </div>
        <h5>KEY USERS - Cloud Platform + Dashboard</h5>
        <div className="grid user">
          <div className="grid-item">
            <StaticImage
              src="../../images/hme-1/Persona_4.jpg"
              alt="Project"
              placeholder="dominantColor"
              className="persona"
            />
            <p>Franchise Owner</p>
          </div>
          <div className="grid-item">
            <StaticImage
              src="../../images/hme-1/Persona_5.jpg"
              alt="Project"
              placeholder="dominantColor"
              className="persona"
            />
            <p>Regional Manager</p>
          </div>
          <div className="grid-item">
            <StaticImage
              src="../../images/hme-1/Persona_2.jpg"
              alt="Project"
              placeholder="dominantColor"
              className="persona"
            />
            <p>Store Manager</p>
          </div>
        </div>
      </div>

      <div className="screens">
        <div className="project-label">
          <p>Base Station Work Flows</p>
        </div>
        <StaticImage
          src="../../images/hme-1/workflow-1.png"
          alt="Project"
          placeholder="dominantColor"
          className="screen"
        />
        <StaticImage
          src="../../images/hme-1/workflow-2.png"
          alt="Project"
          placeholder="dominantColor"
          className="screen"
        />
        <div className="project-label">
          <p>Visual Design Language Development</p>
        </div>
        <StaticImage
          src="../../images/hme-1/hme-art2.png"
          alt="Project"
          placeholder="dominantColor"
          className="screen"
        />
        <StaticImage
          src="../../images/hme-1/HME_ComponentLibrary.png"
          alt="Project"
          placeholder="dominantColor"
          className="screen"
        />
        <div className="project-label">
          <p>Applied Design - Base Station</p>
        </div>

        <StaticImage
          src="../../images/hme-1/ui-3.png"
          alt="Project"
          placeholder="dominantColor"
          className="screen"
        />
        <div className="project-label">
          <p>Applied Design - Cloud Portal</p>
        </div>
        <StaticImage
          src="../../images/hme-1/HME_CloudUI_1.png"
          alt="Project"
          placeholder="dominantColor"
          className="screen"
        />
        <StaticImage
          src="../../images/hme-1/HME_CloudUI_2.png"
          alt="Project"
          placeholder="dominantColor"
          className="screen"
        />
        <StaticImage
          src="../../images/hme-1/HME_CloudUI_3.png"
          alt="Project"
          placeholder="dominantColor"
          className="screen"
        />
      </div>

      <div className="footer-nav">
        <Link to="/">&#x2190; Recent work</Link>
        <span></span>
        <Link to="/work/oxygen-learning">Next Project &#8594; </Link>
      </div>
    </Wrapper>
  </Layout>
)

export default SecondPage
